@import "reset";
@import "pages";
@import "variables";

html {
  scrollbar-width: none;
}

body {
  margin: 0;

  width: 100%;
  height: 100%;
  min-height: 100vh;

  font-family: "Roboto", sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }

  & * {
    box-sizing: border-box;
  }

  &.only-dialog * {
    pointer-events: none;
  }
  &.only-dialog .dialog * {
    pointer-events: all;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.d-flex {
  display: flex;
}
.f {
  &-col {
    flex-direction: column;
  }
  &-wrap {
    flex-wrap: wrap;
  }
  &-ccenter {
    justify-content: center;
  }
  &-center {
    justify-items: center;
  }
  &-acenter {
    align-items: center;
  }
}

.w {
  &-25 {
    width: 25% !important;
  }
  &-50 {
    width: 50% !important;
  }
  &-100 {
    width: 100% !important;
  }
}
.h {
  &-100 {
    height: 100% !important;
  }
}

.on-hover {
  &:hover {
    cursor: pointer !important;
  }
  &-input {
    &:hover {
      cursor: text;
    }
  }
}

.text-no-decoration {
  text-decoration: none;
}

.no-visible-scroll {
  overflow: auto !important;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.app-loader-anim {
  & svg path {
    animation: change-color 5s ease-in-out infinite 1.5s;

    @keyframes change-color {
      0% {
        fill: #000;
      }
      10% {
        fill: #eee;
      }
      20% {
        fill: #222;
      }
      100% {
        fill: #000;
      }
    }
  }
}
