.editor {
  &-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;

    height: 100vh;
    min-height: 100vh;
    min-width: 100vw;
    padding-top: 50px;
    gap: 50px;

    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (max-width: 925px) {
      padding: 32px 16px !important;
      padding-top: 16px !important;
      gap: 32px !important;
    }
  }

  &-idea {
    &-wrapper {
      width: 70%;
      height: 100%;
      padding-right: 80px;

      @media (max-width: 925px) {
        width: 100%;
        padding-right: 0;
      }
    }

    &-scroll2top {
      &-btn {
        display: flex;
        justify-content: center;
        align-items: center;

        position: fixed;
        bottom: 32px;
        right: 50px;
        -webkit-tap-highlight-color: transparent;

        @media (max-width: 925px) {
          bottom: 88px;
          right: 18px;
        }
      }
    }
  }

  &-btn {
    &-goback {
      margin-left: -8px;
    }
  }

  &-on-hover {
    &:hover {
      cursor: text;
    }
  }

  &-form {
    &-wrapper {
      position: relative;
      height: 100%;
      height: calc(100vh - 130px);
    }

    &-spacer {
      content: "";
      height: calc(100% - 200px);
      width: 100%;

      @media (max-width: 925px) {
        height: 200px;
      }
    }

    &-progress {
      &-container {
        position: fixed;
        top: 10%;
        right: 5.5%;
        width: 160px;

        &-preview {
          padding: 8px;
          border-radius: 12px;
        }
      }
    }
  }

  &-input {
    &-title {
      font-family: "Montserrat Alternates", sans-serif !important;
      font-size: 50px !important;
      line-height: 60px !important;
      width: 100%;
      height: auto;
      resize: none;
      overflow: hidden;
    }
    &-description {
      font-size: 20px;
      line-height: 30px;
    }
  }
}

.settings {
  &-layout {
    height: 100vh !important;
    min-height: 500px !important;
    padding: 24px !important;
  }

  &-container {
    overflow: hidden;
    position: relative;
    margin: auto 0;
    width: calc(61% + 48px) !important;
    min-width: 880px !important;
    height: calc(54.5% + 48px) !important;
  }

  &-mobile {
    &-layout {
      padding: 0 !important;
    }

    &-container {
      display: flex;
      flex-direction: column;

      width: 100% !important;
      height: 100% !important;
      border-radius: 0 !important;
      padding: 16px !important;
    }

    &-goback {
      display: flex;
      flex-direction: column;
      opacity: 1;
      margin-bottom: 0;

      transition: all 0.35s ease-in-out 0.7s;

      &.hide {
        opacity: 0;
        margin-bottom: -74px;
      }
    }
  }

  &-company {
    &-user {
      padding-bottom: 12px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);
      margin-bottom: 12px;

      &-role {
        width: 100% !important;

        & * {
          width: 100%;
        }
      }
    }
  }
}
